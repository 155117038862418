import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.css'
})
export class FooterComponent implements OnInit {
    fb: any;
    twitter: any;
    linkedin: any;
    youtube: any;
    github: any;
    instagram: any;
    telegram:any;
    year: any;
    ngOnInit(): void {
      this.fb = 'https://www.facebook.com/sparkouttech';
      this.twitter = 'https://twitter.com/sparkouttech';
      this.linkedin = 'https://www.linkedin.com/company/sparkouttech/mycompany/';
      this.youtube = 'https://www.youtube.com/sparkouttech';
      this.github = 'https://github.com/sparkouttech';
      this.instagram = 'https://www.instagram.com/sparkout_tech/';
      this.telegram = 'https://t.me/sparkouttechblockchainx';
      let date = new Date();
      this.year = date.getFullYear();
    }
    scrollToTop() {
      window.scrollTo(0, 0);
    }
  }
