import { Component,APP_ID,PLATFORM_ID, inject, } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { CommonModule, isPlatformBrowser,isPlatformServer, } from "@angular/common";
import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, HeaderComponent, FooterComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent {
 appId = inject(PLATFORM_ID) as Object
//  isPlatFormBrowser = inject(isPlatformBrowser)
//  isServePlatForm = inject(isPlatformServer)
  title = 'angularSsr';
constructor(){
  console.log(isPlatformBrowser(this.appId),"client Side")
  console.log(isPlatformServer(this.appId),"Server Side")
}

}
