import { Routes } from '@angular/router';

export const routes: Routes = [
  { path: '', loadComponent: () => import('./mobile-app-development-agency-austin/mobile-app-development-agency-austin.component').then(mod => mod.MobileAppDevelopmentAgencyAustinComponent), pathMatch: 'full' },
  { path: 'home', loadComponent: () => import('./home/home.component').then(mod => mod.HomeComponent) },
  { path: 'about-us', loadComponent: () => import('./about/about.component').then(mod => mod.AboutComponent) },
  { path: 'portfolio', loadComponent: () => import('./portfolio/portfolio.component').then(mod => mod.PortfolioComponent) },
  { path: 'design-portfolio', loadComponent: () => import('./design-portfolio/design-portfolio.component').then(mod => mod.DesignPortfolioComponent) },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];


