<header>
  <nav class="navbar navbar-expand-lg navbar-light bg-white" id="navbar_top">
    <div class="container">
      <a class="navbar-brand me-0" [routerLink]="['/']">
        <img
          src="assets/images/sparkout-logo.svg"
          width="320"
          height="73"
          alt="Sparkout-tech-solution"
          title="Sparkout Tech Solution"
          class="img-fluid"
          loading="lazy"
        />
        <!-- <img
          loading="lazy"
          data-src="assets/images/logo/sparkout-logo.svg"
          width="320"
          height="73"
          alt="Sparkout-tech-solution"
          title="Sparkout Tech Solution"
          class="img-fluid" *ngIf="!country"
        /> -->
        <div class="btr">&nbsp;</div>
      </a>
      <div class="btn--hover d-lg-none d-col-block">
        <a class="btn about-us" role="button" [routerLink]="['/contact-us/']"
          >Get a Quote</a
        >
      </div>
      <button
        class="btn btn-primary navbar-toggler"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasRight"
        aria-controls="offcanvasRight"
        aria-label="navbar-toggler"
      >
        <span class="">
          <i class="bi bi-list"></i>
        </span>
      </button>
      <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
        <ul class="navbar-nav mx-auto mb-2 mb-lg-0">
          <li
            class="nav-item dropdown"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <div
              class="nav-link font--semibold text--md dropdown-toggle"
              role="button"
              data-bs-toggle="dropdown"
              >Solutions <i class="bi bi-chevron-down"></i>
            </div>
            <div class="dropdown-menu solutions-menu">
              <div class="row">
                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                  <ul class="mega-menu-links">
                    <h4>Blockchain Solutions</h4>
                    <li routerLinkActive="active">
                      <a
                        href="https://www.blockchainx.tech/nft-marketplace-development/"
                        target="_blank"
                        class="nav-link"
                        >NFT Marketplace
                      </a>
                    </li>
                    <li routerLinkActive="active">
                      <a
                        href="https://www.blockchainx.tech/metaverse-development-company/"
                        target="_blank"
                        class="nav-link"
                        >Metaverse
                      </a>
                    </li>
                    <li routerLinkActive="active">
                      <a
                        class="nav-link"
                        [routerLink]="['/blockchain-development-company/']"
                        >Enterprise Blockchain Solutions
                      </a>
                    </li>
                    <h4>Digital Experience (DX)</h4>
                    <li routerLinkActive="active">
                      <a class="nav-link" [routerLink]="['/ui-ux-development/']"
                        >Customer Experience - UI/UX
                      </a>
                    </li>
                    <li routerLinkActive="active">
                      <a class="nav-link" [routerLink]="['/mobile-app-design-agency/']"
                        >User-Centric Mobile Designs
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                  <ul class="mega-menu-links">
                    <h4>Digital Solutions</h4>
                    <li routerLinkActive="active">
                      <a
                        class="nav-link"
                        href="https://eatzilla.info/"
                        target="_blank"
                        >AI - Ondemand Delivery Solutions</a
                      >
                    </li>
                    <li routerLinkActive="active">
                      <a
                        class="nav-link"
                        href="https://www.rentalslew.com/"
                        target="_blank"
                        >Rental Management</a
                      >
                    </li>
                    <li routerLinkActive="active">
                      <a
                        class="nav-link"
                        href="https://www.transgenie.io/"
                        target="_blank"
                        >SupplyChain Traceability on Blockchain</a
                      >
                    </li>
                    <li routerLinkActive="active">
                      <a
                        class="nav-link"
                        [routerLink]="['/ai-document-understanding/']"
                        >Intelligent Document Understanding AI</a
                      >
                    </li>
                    <li routerLinkActive="active">
                      <a
                        class="nav-link"
                        [routerLink]="['/chat-app-development/']"
                        >Enterprise Chat Application Development</a
                      >
                    </li>
                    <li routerLinkActive="active">
                      <a
                        class="nav-link"
                        [routerLink]="['/hrms-software-development/']"
                        >Human Resource Management Software Development</a
                      >
                    </li>

                  </ul>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-6">
                  <div class="row">
                    <div class="col-lg-6">
                      <ul class="mega-menu-links">
                        <h4>IT Life Cycle</h4>
                        <li class="nav-item" routerLinkActive="active">
                          <a
                            class="nav-link"
                            [routerLink]="[
                              '/custom-software-development-services/'
                            ]"
                            >Custom Software Development</a
                          >
                        </li>
                        <li class="nav-item" routerLinkActive="active">
                          <a
                            class="nav-link"
                            [routerLink]="[
                              '/artificial-intelligence-application-development-company/'
                            ]"
                            >AI/ML Development</a
                          >
                        </li>
                        <li class="nav-item" routerLinkActive="active">
                          <a
                            class="nav-link"
                            [routerLink]="['/generative-ai-development/']"
                            >Generative AI</a
                          >
                        </li>
                        <li class="nav-item" routerLinkActive="active">
                          <a
                            class="nav-link"
                            [routerLink]="['/web-application-development/']"
                            >Modern Web Services</a
                          >
                        </li>
                        <li class="nav-item" routerLinkActive="active">
                          <a
                            class="nav-link"
                            [routerLink]="['/mobile-application-development/']"
                            >Mobile Solutions</a
                          >
                        </li>
                        <li class="nav-item" routerLinkActive="active">
                          <a class="nav-link" [routerLink]="['/devops-services/']"
                            >DevOps Solutions</a
                          >
                        </li>
                        <li class="nav-item" routerLinkActive="active">
                          <a
                            class="nav-link"
                            [routerLink]="[
                              '/quality-assurance-and-testing-services/'
                            ]"
                            >Quality Assurance & Testing</a
                          >
                        </li>
                        <li class="nav-item" routerLinkActive="active">
                          <a
                            class="nav-link"
                            [routerLink]="['/ar-vr-application-development/']"
                            >Spatial Computing - AR / VR / MR</a
                          >
                        </li>
                      </ul>
                    </div>
                    <div class="col-lg-6">
                      <ul class="mega-menu-links mt">
                        <li class="nav-item" routerLinkActive="active">
                          <a
                            class="nav-link"
                            [routerLink]="['/internet-of-things/']"
                            >Internet of Things (IoT)</a
                          >
                        </li>
                        <li class="nav-item" routerLinkActive="active">
                          <a
                            class="nav-link"
                            [routerLink]="['/staff-augmentation-services/']"
                            >Staff Augmentation Services</a
                          >
                        </li>
                        <li class="nav-item" routerLinkActive="active">
                          <a
                            class="nav-link"
                            [routerLink]="['/mvp-development-services/']"
                            >MVP Development Services</a
                          >
                        </li>
                        <li class="nav-item" routerLinkActive="active">
                          <a
                            class="nav-link"
                            [routerLink]="['/application-modernization-services/']"
                            >Application Modernization Services</a
                          >
                        </li>
                        <li class="nav-item" routerLinkActive="active">
                          <a
                            class="nav-link"
                            [routerLink]="['/cloud-migration-services/']"
                            >Cloud Migration Services</a
                          >
                        </li>
                        <li class="nav-item" routerLinkActive="active">
                          <a
                            class="nav-link"
                            [routerLink]="['/software-modernization-services/']"
                            >Software Modernization Services</a
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li
            class="nav-item dropdown"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <div
              class="nav-link font--semibold text--md dropdown-toggle"
              data-bs-toggle="dropdown" role="button"
              >Industries <i class="bi bi-chevron-down"></i>
            </div>
            <ul class="dropdown-menu">
              <li class="nav-item" routerLinkActive="active">
                <a
                  class="nav-link"
                  [routerLink]="['/online-food-delivery-solution/']"
                  >Food & Beverages</a
                >
              </li>
              <li class="nav-item" routerLinkActive="active">
                <a
                  class="nav-link"
                  [routerLink]="['/travel-application-development/']"
                  >Travel</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  href="https://www.transgenie.io/"
                  target="_blank"
                  >Supply Chain
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  [routerLink]="['/fintech-software-development-company/']"
                  >Fintech</a
                >
              </li>
              <li class="nav-item" routerLinkActive="active">
                <a
                  class="nav-link"
                  [routerLink]="['/real-estate-app-development/']"
                >
                Real Estate
                </a>
              </li>
              <li class="nav-item" routerLinkActive="active">
                <a
                  class="nav-link"
                  [routerLink]="['/healthcare-software-development/']"
                >
                Healthcare
                </a>
              </li>
              <li class="nav-item" routerLinkActive="active">
                <a
                  class="nav-link"
                  [routerLink]="['/government-software-development-services/']"
                >
                Government
                </a>
              </li>
            </ul>
          </li>
          <li
            class="nav-item dropdown"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <div
              class="nav-link font--semibold text--md dropdown-toggle"
              data-bs-toggle="dropdown" role="button"
              >Hire <i class="bi bi-chevron-down"></i>
            </div>
            <ul class="dropdown-menu">
              <li class="nav-item" routerLinkActive="active">
                <a
                  class="nav-link"
                  [routerLink]="['/hire-nodejs-developers/']"
                  >Hire NodeJS Developers</a
                >
              </li>
              <li class="nav-item" routerLinkActive="active">
                <a
                  class="nav-link"
                  [routerLink]="['/hire-reactjs-developers/']"
                  >Hire ReactJS Developers</a
                >
              </li>
              <li class="nav-item" routerLinkActive="active">
                <a
                  class="nav-link"
                  [routerLink]="['/hire-angularjs-developers/']"
                  >Hire AngularJS Developers</a
                >
              </li>
              <li class="nav-item" routerLinkActive="active">
                <a
                  class="nav-link"
                  [routerLink]="['/hire-android-developers/']"
                  >Hire Android Developers</a
                >
              </li>
              <li class="nav-item" routerLinkActive="active">
                <a
                  class="nav-link"
                  [routerLink]="['/hire-ios-developers/']"
                  >Hire IOS Developers</a
                >
              </li>
            </ul>
          </li>

          <li
            class="nav-item dropdown"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <div
              class="nav-link font--semibold text--md dropdown-toggle"
              data-bs-toggle="dropdown" role="button"
              >Company <i class="bi bi-chevron-down"></i>
            </div>
            <ul class="dropdown-menu company-menu">
              <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" [routerLink]="['/about-us/']">About Us </a>
              </li>
              <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" [routerLink]="['/portfolio/']">Clients</a>
              </li>
              <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" [routerLink]="['/design-portfolio/']"
                  >Design Portfolio</a
                >
              </li>
              <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" [routerLink]="['/careers/']">Careers</a>
              </li>

              <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" [routerLink]="['/blog/']">Blog</a>
              </li>
              <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" [routerLink]="['/news/']">News</a>
              </li>
              <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" [routerLink]="['/case-studies/']"
                  >Case Study</a
                >
              </li>
            </ul>
          </li>
        </ul>
        <div class="btn--hover">
          <div class="btr">&nbsp;</div>
          <a class="btn about-us" role="button" [routerLink]="['/contact-us/']"
            >Get a Quote
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </a>
        </div>
      </div>
    </div>
  </nav>
</header>

<div
  class="offcanvas offcanvas-end"
  tabindex="-1"
  id="offcanvasRight"
  aria-labelledby="offcanvasRightLabel"
>
  <div class="offcanvas-header">
    <div id="offcanvasRightLabel">
      <a
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasRight"
        aria-controls="offcanvasRight"
        class="navbar-brand"
        [routerLink]="['/']"
      >
        <img
          loading="lazy"
          data-src="assets/images/logo/sparkout-logo.svg"
          width="200"
          height="50"
          alt="Sparkout-tech-solution"
          title="Sparkout Tech Solution"
          class="img-fluid"
        />
      </a>
    </div>
    <button
      type="button"
      class="btn-close text-reset"
      data-bs-dismiss="offcanvas"
      aria-label="Close"
    ></button>
  </div>
  <div class="offcanvas-body">
    <nav class="navbar-expand-lg navbar-light bg-white">
      <ul class="navbar-nav mx-auto">
        <li
          class="nav-item dropdown dropdown-mega position-static"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <div
            class="nav-link font--semibold text--md dropdown-toggle"
            data-bs-toggle="dropdown"
            >Solutions <i class="bi bi-chevron-down"></i>
          </div>
          <div class="dropdown-menu">
            <div class="mega-content mega-content__about py-lg-0">
              <ul class="mega-menu-links">
                <h4>Blockchain Solutions</h4>
                <li class="nav-item" routerLinkActive="active">
                  <a
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                    class="nav-link"
                    href="https://www.blockchainx.tech/nft-marketplace-development/"
                    target="_blank"
                    >NFT Marketplace
                  </a>
                </li>
                <li class="nav-item" routerLinkActive="active">
                  <a
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                    class="nav-link"
                    href="https://www.blockchainx.tech/metaverse-development-company/"
                    target="_blank"
                    >Metaverse</a
                  >
                </li>
                <li class="nav-item" routerLinkActive="active">
                  <a
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                    class="nav-link"
                    [routerLink]="['/blockchain-development-company/']"
                    >Enterprise Blockchain Solutions
                  </a>
                </li>
                <h4>Digital Experience (DX)</h4>
                <li class="nav-item" routerLinkActive="active">
                  <a
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                    class="nav-link"
                    [routerLink]="['/ui-ux-development/']"
                    >Customer Experience - UI/UX
                  </a>
                </li>
                <h4>Digital Solutions</h4>
                <li class="nav-item" routerLinkActive="active">
                  <a
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                    class="nav-link"
                    href="https://eatzilla.info/"
                    target="_blank"
                    >AI - Ondemand Delivery Solutions</a
                  >
                </li>
                <li class="nav-item" routerLinkActive="active">
                  <a
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                    class="nav-link"
                    href="https://www.rentalslew.com/"
                    target="_blank"
                    >Rental Management</a
                  >
                </li>
                <li class="nav-item" routerLinkActive="active">
                  <a
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                    class="nav-link"
                    href="https://www.transgenie.io/"
                    target="_blank"
                    >SupplyChain Traceability on Blockchain</a
                  >
                </li>
                <li class="nav-item" routerLinkActive="active">
                  <a
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                    class="nav-link"
                    [routerLink]="['/ai-document-understanding/']"
                    >Intelligent Document Understanding AI</a
                  >
                </li>
                <li class="nav-item" routerLinkActive="active">
                  <a
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                    class="nav-link"
                    [routerLink]="['/chat-app-development/']"
                    >Enterprise Chat Application Development</a
                  >
                </li>
                <li class="nav-item" routerLinkActive="active">
                  <a
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                    class="nav-link"
                    [routerLink]="['/hrms-software-development/']"
                    >Human Resource Management Software Development</a
                  >
                </li>
                <!-- <li class="nav-item" routerLinkActive="active"><a
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasRight"
                          aria-controls="offcanvasRight"
                          class="nav-link" [routerLink]="['/contact-us']">PikChat - AI Chat Messenger</a></li> -->
                <h4>IT Life Cycle</h4>
                <li class="nav-item" routerLinkActive="active">
                  <a
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                    class="nav-link"
                    [routerLink]="[
                      '/custom-software-development-services/'
                    ]"
                  >
                  Custom Software Development
                  </a>
                </li>
                <li class="nav-item" routerLinkActive="active">
                  <a
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                    class="nav-link"
                    [routerLink]="[
                      '/artificial-intelligence-application-development-company/'
                    ]"
                  >
                    AI/ML Development
                  </a>
                </li>
                <li class="nav-item" routerLinkActive="active">
                  <a
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                    class="nav-link"
                    [routerLink]="['/generative-ai-development/']"
                  >
                    Generative AI
                  </a>
                </li>
                <li class="nav-item" routerLinkActive="active">
                  <a
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                    class="nav-link"
                    [routerLink]="['/web-application-development/']"
                  >
                    Modern Web Services
                  </a>
                </li>
                <li class="nav-item" routerLinkActive="active">
                  <a
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                    class="nav-link"
                    [routerLink]="['/mobile-application-development/']"
                  >
                    Mobile Solutions
                  </a>
                </li>
                <li class="nav-item" routerLinkActive="active">
                  <a
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                    class="nav-link"
                    [routerLink]="['/devops-services/']"
                    >DevOps Solutions
                  </a>
                </li>
                <li class="nav-item" routerLinkActive="active">
                  <a
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                    class="nav-link"
                    [routerLink]="['/quality-assurance-and-testing-services/']"
                  >
                    Quality Assurance & Testing
                  </a>
                </li>
                <li class="nav-item" routerLinkActive="active">
                  <a
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                    class="nav-link"
                    [routerLink]="['/ar-vr-application-development/']"
                  >
                    Spatial Computing - AR / VR / MR
                  </a>
                </li>
                <li class="nav-item" routerLinkActive="active">
                  <a
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                    class="nav-link"
                    [routerLink]="['/internet-of-things/']"
                  >
                    Internet of Things (IOT)
                  </a>
                </li>
                <li class="nav-item" routerLinkActive="active">
                  <a
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasRight"
                  aria-controls="offcanvasRight"
                  class="nav-link"
                    [routerLink]="['/staff-augmentation-services/']"
                    >Staff Augmentation Services</a
                  >
                </li>
                <li class="nav-item" routerLinkActive="active">
                  <a
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasRight"
                  aria-controls="offcanvasRight"
                  class="nav-link"
                    [routerLink]="['/mvp-development-services/']"
                    >MVP Development Services</a
                  >
                </li>
                <li class="nav-item" routerLinkActive="active">
                  <a
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasRight"
                  aria-controls="offcanvasRight"
                  class="nav-link"
                    [routerLink]="['/application-modernization-services/']"
                    >Application Modernization Services</a
                  >
                </li>
                <li class="nav-item" routerLinkActive="active">
                  <a
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasRight"
                  aria-controls="offcanvasRight"
                  class="nav-link"
                    [routerLink]="['/cloud-migration-services/']"
                    >Cloud Migration Services</a
                  >
                </li>
                <li class="nav-item" routerLinkActive="active">
                  <a
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasRight"
                  aria-controls="offcanvasRight"
                  class="nav-link"
                    [routerLink]="['/software-modernization-services/']"
                    >Software Modernization Services</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li
          class="nav-item dropdown dropdown-mega position-static"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <div
            class="nav-link font--semibold text--md dropdown-toggle"
            data-bs-toggle="dropdown"
            >Industries <i class="bi bi-chevron-down"></i>
          </div>
          <div class="dropdown-menu">
            <div class="mega-content mega-content__about py-lg-0">
              <ul class="mega-menu-links">
                <li class="nav-item" routerLinkActive="active">
                  <a
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                    class="nav-link"
                    [routerLink]="['/online-food-delivery-solution/']"
                  >
                    Food & Beverages</a
                  >
                </li>
                <li class="nav-item" routerLinkActive="active">
                  <a
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                    class="nav-link"
                    [routerLink]="['/travel-application-development/']"
                  >
                    Travel</a
                  >
                </li>
                <li class="nav-item" routerLinkActive="active">
                  <a
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                    class="nav-link"
                    href="https://www.transgenie.io/"
                    target="_blank"
                    >Supply Chain
                  </a>
                </li>
                <li class="nav-item" routerLinkActive="active">
                  <a
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                    class="nav-link"
                    [routerLink]="['/fintech-software-development-company/']"
                  >
                    Fintech
                  </a>
                </li>
                <li class="nav-item" routerLinkActive="active">
                  <a
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                    class="nav-link"
                    [routerLink]="['/real-estate-app-development/']"
                  >
                  Real Estate
                  </a>
                </li>
                <li class="nav-item" routerLinkActive="active">
                  <a
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                    class="nav-link"
                    [routerLink]="['/healthcare-software-development/']"
                  >
                  Healthcare
                  </a>
                </li>
                <li class="nav-item" routerLinkActive="active">
                  <a
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                    class="nav-link"
                    [routerLink]="['/government-software-development-services/']"
                  >
                  Government
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li
          class="nav-item dropdown dropdown-mega position-static"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <div
            class="nav-link font--semibold text--md dropdown-toggle"
            data-bs-toggle="dropdown"
            >Hire <i class="bi bi-chevron-down"></i>
          </div>
          <div class="dropdown-menu">
            <div class="mega-content mega-content__about py-lg-0">
              <ul class="mega-menu-links">
                <li class="nav-item" routerLinkActive="active">
                  <a
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                    class="nav-link"
                    [routerLink]="['/hire-nodejs-developers/']"
                  >
                  Hire NodeJS Developers</a
                  >
                </li>
                <li class="nav-item" routerLinkActive="active">
                  <a
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                    class="nav-link"
                    [routerLink]="['/hire-reactjs-developers/']"
                  >
                  Hire ReactJS Developers</a
                  >
                </li>
                <li class="nav-item" routerLinkActive="active">
                  <a
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                    class="nav-link"
                    [routerLink]="['/hire-angularjs-developers/']"
                  >
                  Hire Angular Developers</a
                  >
                </li>
                <li class="nav-item" routerLinkActive="active">
                  <a
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                    class="nav-link"
                    [routerLink]="['/hire-android-developers/']"
                  >
                  Hire Android Developers</a
                  >
                </li>
                <li class="nav-item" routerLinkActive="active">
                  <a
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                    class="nav-link"
                    [routerLink]="['/hire-ios-developers/']"
                  >
                  Hire IOS Developers</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li
          class="nav-item dropdown dropdown-mega position-static"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <div
            class="nav-link font--semibold text--md dropdown-toggle"
            data-bs-toggle="dropdown"
            >Company <i class="bi bi-chevron-down"></i>
          </div>
          <div class="dropdown-menu">
            <div class="mega-content mega-content__about py-lg-0">
              <ul class="mega-menu-links">
                <li class="nav-item" routerLinkActive="active">
                  <a
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                    class="nav-link"
                    [routerLink]="['/about-us/']"
                    >About Us</a
                  >
                </li>
                <li class="nav-item" routerLinkActive="active">
                  <a
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                    class="nav-link"
                    [routerLink]="['/portfolio/']"
                    >Clients</a
                  >
                </li>
                <li class="nav-item" routerLinkActive="active">
                  <a
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                    class="nav-link"
                    [routerLink]="['/design-portfolio/']"
                    >Design Portfolio</a
                  >
                </li>
                <li class="nav-item" routerLinkActive="active">
                  <a
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                    class="nav-link"
                    [routerLink]="['/careers/']"
                    >Career</a
                  >
                </li>
                <li class="nav-item" routerLinkActive="active">
                  <a
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                    class="nav-link"
                    [routerLink]="['/blog/']"
                    >Blog</a
                  >
                </li>
                <li class="nav-item" routerLinkActive="active">
                  <a
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                    class="nav-link"
                    [routerLink]="['/news/']"
                    >News</a
                  >
                </li>
                <li class="nav-item" routerLinkActive="active">
                  <a
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                    class="nav-link"
                    [routerLink]="['/case-studies/']"
                    >Case Study</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul>
      <div class="btn--hover">
        <a
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasRight"
          aria-controls="offcanvasRight"
          class="btn about-us"
          role="button"
          [routerLink]="['/contact-us/']"
          >Get a Quote
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </a>
      </div>
    </nav>
  </div>
</div>
