<section class="footer">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-md-3 d-md-none d-lg-block col-sm-12">
        <div class="footer-logo">
          <img
          src="assets/images/sparkout-logo.svg"
          width="200"
          height="44"
          alt="Sparkout-tech-solution"
          title="Sparkout Tech Solution"
          class="img-fluid"
          loading="lazy"
        />
        <!-- <img
          loading="lazy"
          data-src="assets/images/logo/sparkout-logo.svg"
          width="200"
          height="44"
          alt="Sparkout-tech-solution"
          title="Sparkout Tech Solution"
          class="img-fluid" *ngIf="!country"
        /> -->
        </div>
      </div>
      <div class="col-lg-9 col-md-12 col-sm-12">
        <div class="row">
          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="footer-heading">
              <h3>Registered Office</h3>
              <p>
                No. 3 Gem Colony, Near Siva Hospital, Ganapathy,
                <span class="span-block"
                  > Coimbatore, Tamil Nadu,
                </span>
                <span class="span-block">India - 641 006.</span>
                <!-- <span class="register-block">CIN: U72502TZ2022PTC038654</span>
                <span class="register-block">Mobile: <a href="tel:+91 86677 26177">+91 86677 26177</a> </span>
                <span class="register-block">Email: <a href="mailto:contact@sparkouttech.com">contact@sparkouttech.com</a></span> -->
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="footer-heading">
              <h3>Branch Office</h3>
              <p>
                No. 2 C.M.Nagar, Ramakrishnapuram,
                <span class="span-block">Coimbatore, Tamil Nadu, </span>
                <span class="span-block">India - 641 006.</span>
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="footer-heading">
              <h3>Branch Office - US</h3>
              <p>
                8 The Green, STE A
                <span class="span-block">Dover, De, Kent </span>
                <span class="span-block"> United States - 19901.</span>
              </p>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="footer-heading">
              <p class="register-para">
                <span class="register-block">CIN: U72502TZ2022PTC038654</span>
                <span>|</span>
                <span class="register-block">M: <a href="tel:+91 86677 26177">+91 86677 26177</a> </span>
                <span>|</span>
                <span class="register-block">E: <a href="mailto:contact@sparkouttech.com">contact&#64;sparkouttech.com</a></span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="lines-white">
          <hr />
        </div>
      </div>
    </div>
    <div class="row row-cols-2 row-cols-sm-2 row-cols-md-2 row-cols-lg-4">
      <div class="col">
        <div class="footer-heading">
          <h3>Overview</h3>
          <ul>
            <li><a [routerLink]="['/about-us/']">About Us</a></li>
            <li><a [routerLink]="['/careers/']">Careers</a></li>
            <li><a [routerLink]="['/portfolio/']">Clients</a></li>
            <li><a [routerLink]="['/blog/']">Blog</a></li>
            <li><a [routerLink]="['/news/']">News</a></li>
            <li><a [routerLink]="['/case-studies/']">Case Study</a></li>
          </ul>

          <h3>Hire</h3>
          <ul>
            <li><a [routerLink]="['/hire-nodejs-developers/']">Hire NodeJS Developers</a></li>
            <li><a [routerLink]="['/hire-reactjs-developers/']">Hire ReactJS Developers</a></li>
            <li><a [routerLink]="['/hire-angularjs-developers/']">Hire Angular Developers</a></li>
            <li><a [routerLink]="['/hire-android-developers/']">Hire Android Developers</a></li>
            <li><a [routerLink]="['/hire-ios-developers/']">Hire IOS Developers</a></li>
          </ul>
        </div>
      </div>
      <div class="col">
        <div class="footer-heading no-heading-pb-0">
          <h3>Solutions</h3>
          <ul>
            <li>
              <a [routerLink]="['/ui-ux-development/']"
                >Customer Experience - UI/UX
              </a>
            </li>
            <li>
              <a [routerLink]="['/internet-of-things/']"
                >Internet of Things (IoT)</a
              >
            </li>

            <li>
              <a [routerLink]="['/blockchain-development-company/']"
                >Enterprise Blockchain Solutions
              </a>
            </li>

            <li>
              <a [routerLink]="['/mobile-app-design-agency/']"
                >User-Centric Mobile Designs
              </a>
            </li>
          </ul>
          <h3>Industries</h3>
          <ul>
            <li>
              <a [routerLink]="['/online-food-delivery-solution/']"
                >Food & Beverages</a
              >
            </li>
            <li>
              <a [routerLink]="['/travel-application-development/']">Travel</a>
            </li>
            <li>
              <a [routerLink]="['/fintech-software-development-company/']">
                Fintech
              </a>
            </li>
            <li>
              <a [routerLink]="['/real-estate-app-development/']">
                Real Estate
              </a>
            </li>
            <li>
              <a [routerLink]="['/healthcare-software-development/']">
                Healthcare
              </a>
            </li>
            <li>
              <a [routerLink]="['/government-software-development-services/']">
                Government
              </a>
            </li>
            <!-- <li>
              <a
                [routerLink]="[
                  '/media-and-entertainment-application-development/'
                ]"
                >Media & Entertainment</a
              >
            </li> -->
          </ul>
        </div>
      </div>
      <div class="col">
        <div class="footer-heading no-heading-pb-0">
          <h3>Digital Solutions</h3>
          <ul>
            <li>
              <a
                href="https://eatzilla.info/"
                target="_blank"
                >AI - Ondemand Delivery Solutions</a
              >
            </li>
            <li>
              <a
                href="https://www.rentalslew.com/"
                target="_blank"
                >Rental Management</a
              >
            </li>
            <li>
              <a href="https://www.transgenie.io/" target="_blank"
                >SupplyChain Traceability on Blockchain</a
              >
            </li>
            <li>
              <a [routerLink]="['/ai-document-understanding']"
                >Intelligent Document Understanding AI</a
              >
            </li>
            <li>
              <a [routerLink]="['/chat-app-development/']"
                >Enterprise Chat Application Development</a
              >
            </li>
            <li>
              <a [routerLink]="['/hrms-software-development/']"
                >Human Resource Management Software Development</a
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="col">
        <div class="footer-heading no-heading-pb-0">
          <h3>IT Life Cycle</h3>
          <ul>
            <li>
              <a
                [routerLink]="[
                  '/custom-software-development-services/'
                ]"
                >Custom Software Development</a
              >
            </li>
            <li>
              <a
                [routerLink]="[
                  '/artificial-intelligence-application-development-company/'
                ]"
                >AI/ML Development</a
              >
            </li>
            <li>
              <a [routerLink]="['/generative-ai-development/']"
                >Generative AI</a
              >
            </li>
            <li>
              <a [routerLink]="['/web-application-development/']"
                >Modern Web Services</a
              >
            </li>
            <li>
              <a [routerLink]="['/mobile-application-development/']"
                >Mobile Solutions</a
              >
            </li>
            <li>
              <a [routerLink]="['/devops-services/']">DevOps Solutions</a>
            </li>
            <li>
              <a [routerLink]="['/quality-assurance-and-testing-services/']"
                >Quality Assurance & Testing</a
              >
            </li>
            <li>
              <a [routerLink]="['/ar-vr-application-development/']"
                >Spatial Computing - AR / VR / MR</a
              >
            </li>
            <li>
              <a [routerLink]="['/staff-augmentation-services/']"
                >Staff Augmentation Services</a
              >
            </li>
            <li>
              <a [routerLink]="['/mvp-development-services/']"
                >MVP Development Services</a
              >
            </li>
            <li>
              <a [routerLink]="['/application-modernization-services/']"
                >Application Modernization Services</a
              >
            </li>
            <li>
              <a [routerLink]="['/cloud-migration-services/']"
                >Cloud Migration Services</a
              >
            </li>
            <li>
              <a [routerLink]="['/software-modernization-services/']"
                >Software Modernization Services</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="footer-bottom">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <div class="copyrights">
            <p>
              <a [routerLink]="['/privacy-policy/']">Privacy Policy</a> |
              <a [routerLink]="['/cookies-policy/']"> Cookies Policy</a> |
              <a [routerLink]="['/terms-conditions/']">Terms & Conditions</a>
            </p>
          </div>
        </div>
        <div class="col-md-6 col-sm-12">
          <div class="social-icons d-flex justify-content-end">
            <ul>
              <li>
                <a
                  href="https://wa.me/8667726177"
                  class="contact-icon"
                  title="whatsapp"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets/images/social-network/icon-whatsapp.webp"
                    />
                    <source
                      type="image/png"
                      srcset="assets/images/social-network/icon-whatsapp.png"
                    />
                    <img
                      loading="lazy"
                      data-src="assets/images/social-network/icon-whatsapp.svg"
                      alt="whatsapp"
                      title="Whatsapp"
                      width="24"
                      height="24"
                      class="img-fluid"
                    />
                  </picture>
                </a>
              </li>
              <li>
                <a
                  href="{{ fb }}"
                  title="facebook"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets/images/social-network/icon-facebook.webp"
                    />
                    <source
                      type="image/png"
                      srcset="assets/images/social-network/icon-facebook.png"
                    />
                    <img
                      loading="lazy"
                      data-src="assets/images/social-network/icon-facebook.svg"
                      alt="facebook"
                      title="Facebook"
                      width="24"
                      height="24"
                      class="img-fluid"
                    />
                  </picture>
                </a>
              </li>
              <li>
                <a
                  href="{{ twitter }}"
                  title="twitter"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets/images/social-network/icon-twitter.webp"
                    />
                    <source
                      type="image/png"
                      srcset="assets/images/social-network/icon-twitter.png"
                    />
                    <img
                      loading="lazy"
                      data-src="assets/images/social-network/icon-twitter.svg"
                      alt="twitter"
                      title="Twitter"
                      width="24"
                      height="24"
                      class="img-fluid"
                    />
                  </picture>
                </a>
              </li>
              <li>
                <a
                  href="{{ linkedin }}"
                  title="linkedin"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets/images/social-network/icon-linkedin.webp"
                    />
                    <source
                      type="image/png"
                      srcset="assets/images/social-network/icon-linkedin.png"
                    />
                    <img
                      loading="lazy"
                      data-src="assets/images/social-network/icon-linkedin.svg"
                      alt="linkedin"
                      title="Linkedin"
                      width="24"
                      height="24"
                      class="img-fluid"
                    />
                  </picture>
                </a>
              </li>
              <li>
                <a
                  href="{{ telegram }}"
                  title="telegram"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <picture>
                    <source
                    type="image/webp"
                    srcset="assets/images/social-network/telegram.webp"
                  />
                    <source
                      type="image/png"
                      srcset="assets/images/social-network/telegram.png"
                    />
                    <img
                      loading="lazy"
                      data-src="assets/images/social-network/telegram.png"
                      width="24"
                      height="24"
                      alt="telegram"
                      title="telegram"
                    />
                  </picture>
                </a>
              </li>
              <li>
                <a
                  href="{{ instagram }}"
                  title="instagram"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets/images/social-network/icon-instagram.webp"
                    />
                    <source
                      type="image/png"
                      srcset="assets/images/social-network/icon-instagram.png"
                    />
                    <img
                      loading="lazy"
                      data-src="assets/images/social-network/icon-instagram.svg"
                      width="24"
                      height="24"
                      alt="instagram"
                      title="Instagram"
                    />
                  </picture>
                </a>
              </li>
              <li>
                <a
                  href="{{ youtube }}"
                  title="youtube"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets/images/social-network/icon-youtube.webp"
                    />
                    <source
                      type="image/png"
                      srcset="assets/images/social-network/icon-youtube.png"
                    />
                    <img
                      loading="lazy"
                      data-src="assets/images/social-network/icon-youtube.svg"
                      alt="youtube"
                      title="Youtube"
                      width="24"
                      height="24"
                      class="img-fluid"
                    />
                  </picture>
                </a>
              </li>
              <li>
                <a
                  href="{{ github }}"
                  title="github"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets/images/social-network/icon-github.webp"
                    />
                    <source
                      type="image/png"
                      srcset="assets/images/social-network/icon-github.png"
                    />
                    <img
                      loading="lazy"
                      data-src="assets/images/social-network/icon-github.svg"
                      alt="github"
                      title="Github"
                      width="24"
                      height="24"
                      class="img-fluid"
                    />
                  </picture>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="lines-white">
            <hr />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 order-last order-md-first">
          <p>© Sparkout Tech 2017-{{ year }}. All rights reserved</p>
        </div>
        <div class="col-md-6 order-first order-md-last">
          <div class="protected-screen d-flex justify-content-end">
            <picture>
              <source
                type="image/webp"
                srcset="assets/images/dmca-protected.webp"
              />
              <source
                type="image/png"
                srcset="assets/images/dmca-protected.png"
              />
              <img
                loading="lazy"
                data-src="assets/images/dmca-protected.png"
                alt="dmca-protected"
                title="DMCA Protected"
                width="175"
                height="29"
                class="img-fluid"
              />
            </picture>
          </div>
          <div class="scroller" (click)="scrollToTop()">
            <i class="bi bi-arrow-up"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
